import React, { useState } from 'react';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ProgressBar } from 'react-bootstrap';

const ImageToPDF = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleImageUpload = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
  }

  const handleConvertToPDF = () => {
    setIsProcessing(true);
    html2canvas(document.querySelector("#capture"), {scale: 3}).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      let pdf;
      // If width is greater than height, set orientation to 'landscape'
      if (canvas.width > canvas.height) {
        pdf = new jsPDF('landscape');
      } else {
        pdf = new jsPDF('portrait');
      }
      const imgProps= pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
      setIsProcessing(false);
    });
  }

  return (
    <div className="text-center" style={{ padding: '50px' }}>
      <h1>Image to PDF Converter</h1>

      {isProcessing ? <ProgressBar animated now={100} /> : null}

      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {selectedImage && 
        <div id="capture" style={{ width: '512px', height: '512px', position: 'relative', overflow: 'hidden', margin: '0 auto' }}>
          <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        </div>
      }
      {selectedImage && <button className="btn btn-primary mt-4" onClick={handleConvertToPDF}>Convert to PDF</button>}
    </div>
  );
}

export default ImageToPDF;
