import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  ProgressBar,
  Modal
} from 'react-bootstrap';
import imageCompression from 'browser-image-compression';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';



function ImageCompressionPage() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [compressedImages, setCompressedImages] = useState([]);
  const [downloadAllEnabled, setDownloadAllEnabled] = useState(false);
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);


  const compressImages = useCallback(async () => {

    setProcessing(true);
    const compressedImages = [];
    let originalSize = 0;
    let compressedSize = 0;

    for (const file of selectedFiles) {
      try {
        const compressedFile = await compressImage(file);
        compressedImages.push({
          original: file,
          compressed: compressedFile,
          deleteIcon: true, // Add delete icon to each image
        });
        originalSize += file.size;
        compressedSize += compressedFile.size;
      } catch (error) {
        console.error('Error compressing the image:', error);
        alert('Error compressing the image!')
      }
    }

    setOriginalSize(originalSize);
    setCompressedSize(compressedSize);
    setCompressedImages(compressedImages);
    setDownloadAllEnabled(true);
    setProcessing(false);
  }, [selectedFiles]);



  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  };

  // check if upload box has duplicate files
  const hasDuplicateFiles = (newFiles, existingFiles) => {
    const fileNames = existingFiles.map((file) => file.name);
    return newFiles.some((file) => fileNames.includes(file.name));
  };

  // check if image format is correct
  const isValidImage = (file) => {
    const allowedFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    return allowedFormats.includes(file.type);
  };

  // file input handler
  const handleFileInputChange = (event) => {
    if (selectedFiles.length + event.target.files.length > 3) {
      alert('You can only upload a maximum of 10 files at a time!')

      return;
    }

    const newFiles = Array.from(event.target.files);
    // Check for valid image formats
    const validFiles = newFiles.filter((file) => isValidImage(file));
    const invalidFiles = newFiles.filter((file) => !isValidImage(file));

    if (invalidFiles.length > 0) {
      alert('Only image formats (jpg, png, gif, webp) are allowed.')
      return;
    }

    if (hasDuplicateFiles(validFiles, selectedFiles)) {
      alert('Duplicate files not allowed.');
      return;
    }

    const updatedFiles = [...selectedFiles, ...validFiles];
    setSelectedFiles(updatedFiles);
  };




  // drag and drop handler
  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);

    if (selectedFiles.length + newFiles.length > 10) {
      alert('You can only upload a maximum of 10 files at a time.');
      return;
    }

    // Check for valid image formats
    const validFiles = newFiles.filter((file) => isValidImage(file));
    const invalidFiles = newFiles.filter((file) => !isValidImage(file));

    if (invalidFiles.length > 0) {
      alert('Only image formats (jpg, png, gif, webp) are allowed.');
      return;
    }

    if (hasDuplicateFiles(validFiles, selectedFiles)) {
      alert('Duplicate files not allowed.');
      return;
    }

    const updatedFiles = [...selectedFiles, ...validFiles];
    setSelectedFiles(updatedFiles);
  };

  // prevent default

  const preventDefault = (event) => {
    event.preventDefault();
  };

  // dowload image
  const downloadImage = (compressedFile) => {
    saveAs(compressedFile, `compressed_${compressedFile.name}`);
  };

  const downloadAllImages = async () => {
    const zip = new JSZip();
    compressedImages.forEach((image) => {
      zip.file(`compressed_${image.compressed.name}`, image.compressed);
    });
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'compressed_images.zip');
  };

  const resetQueue = () => {
    setSelectedFiles([]);
    setCompressedImages([]);
    setDownloadAllEnabled(false);
    setOriginalSize(0);
    setCompressedSize(0);
  };

  const truncateName = (name) => {
    const maxLength = 10;
    if (name.length > maxLength) {
      return '...' + name.slice(-maxLength);
    }
    return name;
  };


  const handleCompressImagesClick = () => {
    compressImages();
  };

  const deleteImageBeforeCompression = (index) => {
    const updatedSelectedFiles = [...selectedFiles];

    updatedSelectedFiles.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
  };

  // contact form





  return (


    <div>


      <Modal show={processing} centered>
        <Modal.Body className="text-center">
          <h4>Compressing...</h4>
          <ProgressBar animated now={100} />
        </Modal.Body>
      </Modal>
      <header>
        
      </header>
      <section className="container-fluid">
        <Row>
          <Col md={8} lg={12}>
            <div
              className="border border-dark rounded p-3 my-5 d-flex flex-column justify-content-center align-items-center"
              style={{ minHeight: '60vh' }}
              onDragOver={preventDefault}
              onDrop={handleDrop}

            >
              <input
                type="file"
                multiple
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                id="file-input"
              />
              <label htmlFor="file-input" className="btn btn-dark mb-3">
                Upload Images
              </label>
              <p>or drag and drop images here</p>
              {selectedFiles.length > 0 && (
                <ul>
                  {Array.from(selectedFiles).map((file, index) => (
                    <li key={index} className="d-flex align-items-center mb-2">
                      <span className="me-2">{truncateName(file.name)}</span>
                      {/* Add delete button with dustbin icon for images in the upload box */}
                      <Button
                        variant="danger"
                        onClick={() => deleteImageBeforeCompression(index)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </li>
                  ))}
                </ul>
              )}

              
            </div>
          </Col>
        </Row>
        <Row>
          {/* ... */}
        </Row>


        <Row>
          <Row>

            {compressedImages.map((image, index) => (
              <Col key={index} md={4} className="mb-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={URL.createObjectURL(image.original)}
                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                  />
                  <Card.Body>
                    <Card.Title>{truncateName(image.original.name)}</Card.Title>
                    <ProgressBar
                      now={100}
                      label="Compressed"
                      style={{ display: processing ? 'block' : 'none' }}
                    />
                    <div className="d-flex justify-content-center" style={{ display: !processing ? 'block' : 'none' }}>
                      <Button variant="primary" onClick={() => downloadImage(image.compressed)}>
                        Download
                      </Button>
                    </div>
                  </Card.Body>

                </Card>
              </Col>
            ))}



          </Row>
        </Row>
        {/* Compress button */}
        <div>
          <Col className="d-flex justify-content-center mb-2">
            <Button
              variant="dark"
              onClick={handleCompressImagesClick}
            >
              Compress
            </Button>
          </Col>
        </div>
        {/* download all files button */}
        <div>
          <Col className="d-flex justify-content-center">
            <Button
              variant="dark"
              onClick={downloadAllImages}
              disabled={!downloadAllEnabled}
              className="mx-2 mb-3"
            >
              Download All as ZIP
            </Button>
          </Col>


        </div>
        <div>
          {/* Add Reset Queue button */}
          <section className="container">
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  variant="dark"
                  onClick={resetQueue}
                  className="mx-2 mb-3"
                >
                  Reset Queue
                </Button>
              </Col>
            </Row>
          </section>
          {/* Display total sizes */}
          <section className="container">
            <Row>
              <Col className="d-flex justify-content-center">
                {originalSize > 0 && compressedSize > 0 && (
                  <p>
                    Total size before: {Math.round(originalSize / 1024)} KB <br />
                    Total size after: {Math.round(compressedSize / 1024)} KB
                  </p>
                )}
              </Col>
            </Row>
          </section>


        </div>
      </section>

    </div>
  );
}

export default ImageCompressionPage;

