import React, { useState, useEffect } from 'react';
import { load } from '@tensorflow-models/mobilenet';
import { create } from '@tensorflow/tfjs-core';
import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';
import ImageUpload from './ImageUpload';
import styles from './css/ImageRecognition.module.css';
import { Modal, Spinner } from 'react-bootstrap';

const ImageRecognition = () => {
  const [model, setModel] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const loadModel = async () => {
    console.log("Loading model");
    const model = await load();
    setModel(model);
    console.log("Model loaded");
  };

  useEffect(() => {
    loadModel();
  }, []);

  const predict = async (image) => {
    if (model) {
      setIsProcessing(true);
      console.log("Predicting");
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);
      const predictions = await model.classify(canvas);
      console.log("Predictions: ", predictions);
      setPredictions(predictions);
      setIsProcessing(false);
    }
  };

  const handleImageUpload = (file) => {
    const url = URL.createObjectURL(file);
    setImgUrl(url);  // set state here

    const image = document.createElement('img');
    image.src = url;
    image.onload = () => predict(image);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Image Recognition</h1>
      <ImageUpload onImageUpload={handleImageUpload} />
      <div className={styles.canvasContainer}>
        <canvas id="canvas" className={styles.canvas}></canvas>
      </div>
      {isProcessing && (
        <Modal show={isProcessing} centered>
          <Modal.Body className="text-center">
            <Spinner animation="border" variant="primary" />
            <p>Predicting...</p>
          </Modal.Body>
        </Modal>
      )}
      {predictions
        .filter((prediction) => prediction.probability > 0.0001)
        .map((prediction, i) => (
          <div key={i} className={styles.predictionBox}>
            <h3>{prediction.className}</h3>
            <p>Probability: {prediction.probability.toFixed(4)}</p>
          </div>
        ))}
    </div>
  );
};

export default ImageRecognition;
