import React, { useState, useRef } from "react";
import { fabric } from "fabric";

const ImageManipulator = () => {
  const [canvas, setCanvas] = useState(null);
  const [image, setImage] = useState(null);
  const [inputFile, setInputFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const inputFileRef = useRef(null);

  const initCanvas = (img) => {
    const canvasElement = new fabric.Canvas("canvas");
    setCanvas(canvasElement);
    canvasElement.setWidth(img.width);
    canvasElement.setHeight(img.height);
    const fabricImage = new fabric.Image(img);
    setImage(fabricImage);
    canvasElement.add(fabricImage);
  };

  const handleFileChange = (e) => {
    setInputFile(e.target.files[0]);
    if (e.target.files.length === 0) return;

    const reader = new FileReader();
    reader.onload = (evt) => {
      const img = new Image();
      img.src = evt.target.result;
      img.onload = () => {
        initCanvas(img);
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const applyFilter = async (filterName) => {
    if (!canvas || !image) return;
    setIsProcessing(true);

    const fabricFilter = new fabric.Image.filters[filterName]();
    image.filters.push(fabricFilter);
    await image.applyFilters();
    canvas.renderAll();

    setIsProcessing(false);
  };

  const downloadImage = () => {
    if (!canvas) return;
  
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "image.png";
  
    // Append the link to the document body to trigger the download
    document.body.appendChild(link);
  
    // Trigger the click event on the link
    link.click();
  
    // Remove the link from the document body
    document.body.removeChild(link);
  };
  
  const resetCanvas = () => {
    if (canvas) {
      canvas.clear();
      setImage(null);
      setInputFile(null);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          type="file"
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.webp"
          style={{ display: "none" }}
          ref={inputFileRef}
        />
        <button onClick={() => inputFileRef.current.click()}>Upload Image</button>
        <button onClick={() => applyFilter("Grayscale")} disabled={!image || isProcessing}>
          Apply Grayscale
        </button>
        <button onClick={() => applyFilter("Invert")} disabled={!image || isProcessing}>
          Apply Invert
        </button>
        <button onClick={downloadImage} disabled={!image || isProcessing}>
          Download Image
        </button>
        <button onClick={resetCanvas} disabled={!canvas || isProcessing}>
          Reset
        </button>
      </div>
      {isProcessing && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>Processing...</h2>
        </div>
      )}
      <canvas id="canvas" style={{ border: "1px solid black" }}></canvas>
    </div>
  );
};

export default ImageManipulator;
