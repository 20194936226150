import React, { useState, useContext, Component } from 'react';
// import SignupLogin from './components/SignupLogin';
import ImageCompressionPage from './components/ImageCompressionPage';
import ImageFormatConverter from './components/ImageFormatConverter';
import TextExtractor from './components/TextExtractor';
import DocumentConverter from "./components/DocumentConverter";
import ImageMetadataViewer from "./components/ImageMetadataViewer";
import ImageWatermarker from "./components/ImageWatermarker";
import ImageRecognition from './components/ImageRecognition';
import ColorfulImage from './components/ColorfulImage';
import ImageToPDF from './components/ImageToPdf';
import ObjectDetection from './components/ObjectDetection';
import PrivacyPolicy from './components/privacy.js';
import ImageFilterApp from './components/filter'
import './App.css';
import ContactForm from './components/ContactForm';

// import BackgroundRemover from './components/BackgroundRemover';

import {
  Row,
  Col,
  Card, Navbar, Nav, Modal, Button
} from 'react-bootstrap';


const App = () => {


  const [currentPage, setCurrentPage] = useState('IMC');
  // const { currentUser } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const switchPage = (event) => {
    const targetPage = event.target.dataset.page;
    setCurrentPage(targetPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Navbar className="navbar" variant="light" sticky="top">
        <Navbar.Brand href="#home" className='brand'>IMAGENIZE</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link onClick={() => switchPage('PrivacyPolicy')}>About Us</Nav.Link>
          <Nav.Link onClick={handleShow}>Contact Us</Nav.Link>
          <Nav.Link onClick={() => switchPage('More')}>More</Nav.Link>
        </Nav>
      </Navbar>
      {currentPage === 'IMC' && <ImageCompressionPage />}
      {currentPage === 'IMF' && <ImageFormatConverter />}
      {currentPage === 'ITT' && <TextExtractor />}
      {currentPage === 'CON' && <ContactForm />}
      {currentPage === 'DOC' && <DocumentConverter />}
      {currentPage === 'META' && <ImageMetadataViewer />}
      {currentPage === 'MARK' && <ImageWatermarker />}
      {currentPage === 'IR' && <ImageRecognition />}
      {currentPage === 'COL' && <ColorfulImage />}
      {currentPage === 'ITP' && <ImageToPDF />}
      {currentPage === 'PRIVACY' && <PrivacyPolicy />}
      {currentPage === 'FILTER' && <ImageFilterApp />}



      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={show} onHide={handleClose} className="glass-modal">
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      <section id='more' className="container my-5">
        <h2>More Services</h2>
        <Row>
          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image Compressor</Card.Title>
                <Card.Text>
                  Resize your images easily.
                </Card.Text>
                <button data-page="IMC" onClick={switchPage}>Image Compressor</button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image Format Converter</Card.Title>
                <Card.Text>
                  Convert your images to different formats.
                </Card.Text>
                <button data-page="IMF" onClick={switchPage}>Image Format Converter</button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image to Text</Card.Title>
                <Card.Text>
                  Extract text from jpeg, png, tiff, bmp and many more..
                </Card.Text>
                <button data-page="ITT" onClick={switchPage}>Image to text</button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image Metadata Viewer</Card.Title>
                <Card.Text>
                  Metadata viewer, EXIF extractor
                </Card.Text>
                <button data-page="META" onClick={switchPage}>Metadata viewer</button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image Watermarker</Card.Title>
                <Card.Text>
                  Best image watermarking solution
                </Card.Text>
                <button data-page="MARK" onClick={switchPage}>Image Watermaker</button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image Recognition</Card.Title>
                <Card.Text>
                  Identify objects in your images.
                </Card.Text>
                <button data-page="IR" onClick={switchPage}>Image Recognition</button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image palette</Card.Title>
                <Card.Text>
                  make color palette from images
                </Card.Text>
                <button data-page="COL" onClick={switchPage}>Color palette</button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image To PDF</Card.Title>
                <Card.Text>
                  Convert your images to PDF
                </Card.Text>
                <button data-page="ITP" onClick={switchPage}>Image to PDF</button>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Image Filters</Card.Title>
                <Card.Text>
                  Apply grayscale,sepia, filters to your images
                </Card.Text>
                <button data-page="FILTER" onClick={switchPage}>Filters</button>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Privacy Policy</Card.Title>
                <Card.Text>
                  Privacy policy
                </Card.Text>
                <button data-page="PRIVACY" onClick={switchPage}>Privacy Policy</button>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col md={4}>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Contact Us</Card.Title>
                <Card.Text>
                  We would love to hear from you.
                </Card.Text>
                <button data-page="CON" onClick={switchPage}>Contact Us</button>
              </Card.Body>
            </Card>
          </Col> */}



        </Row>
      </section>
    </div>

  );
};

export default App;
