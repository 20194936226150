import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";



const DocumentConverter = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [outputFormat, setOutputFormat] = useState("docx");

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleOutputFormatChange = (event) => {
        setOutputFormat(event.target.value);
    };

    const convertDocument = async () => {
        if (!selectedFile) {
          alert("Please select a file to convert");
          return;
        }
      
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("outputFormat", outputFormat);
      
        try {
          const response = await axios.post("/convertDocument", formData, {
            responseType: "blob",
          });
      
          if (response.status !== 200) {
            console.error("Error response from server:", response);
            throw new Error("Conversion failed");
          }
      
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `converted.${outputFormat}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error during conversion:", error);
          alert("Conversion failed. Please try again.");
        }
      };
      

    return (
        <div>
            <h2>Document Converter</h2>
            <Form>
                <Form.Group>
                    <Form.Label>Select File</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={handleFileInputChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Output Format</Form.Label>
                    <Form.Control
                        as="select"
                        value={outputFormat}
                        onChange={handleOutputFormatChange}
                    >
                        <option value="docx">DOCX</option>
                        <option value="pdf">PDF</option>
                        {/* Add more options here for the other formats */}
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" onClick={convertDocument}>
                    Convert Document
                </Button>
            </Form>
        </div>
    );
};

export default DocumentConverter;

