import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import styles from './css/ImageFormatConverter.module.css';
import GeoTIFF from 'geotiff';





const ImageFormatConverter = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputFormat, setInputFormat] = useState('image/jpeg');
  const [outputFormat, setOutputFormat] = useState('image/png');
  const [processing, setProcessing] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [convertedFiles, setConvertedFiles] = useState([]);



  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files.length) return;
  
    const validFiles = [];
    for (const file of files) {
      if (file.type !== inputFormat) {
        alert('Please select an image with the correct input format.');
        return;
      }
      validFiles.push(file);
    }
  
    for (const file of validFiles) {
      if (file.type === 'image/tiff') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const buffer = e.target.result;
          const tiff = await GeoTIFF.fromArrayBuffer(buffer);
          const image = await tiff.getImage();
          const width = image.getWidth();
          const height = image.getHeight();
          const rgba = await image.readRGBA();
          const img = new ImageData(rgba, width, height);
  
          setSelectedFile({ type: 'image/tiff', img, file });
        };
        reader.readAsArrayBuffer(file);
      } else {
        setSelectedFile(file);
      }
      setUploadedFiles((prevFiles) => [...prevFiles, file]);
    }
  };
  

  const handleInputFormatChange = (event) => {
    setInputFormat(event.target.value);
  };

  const handleOutputFormatChange = (event) => {
    setOutputFormat(event.target.value);
    const value = event.target.value;
    if (value === inputFormat) {
      alert("Input and output formats can't be the same.");
      return;
    }
    setOutputFormat(value);
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };

  const resetQueue = () => {
    setSelectedFile(null);
    setUploadedFiles([]);
  };

  const onDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileInputChange({ target: { files } });
    }
  };


  const convertImage = async () => {
    // Check if input and output formats are the same
    if (inputFormat === outputFormat) {
      alert("Input and output formats can't be the same.");
      return;
    }
    setProcessing(true);
  
    for (const file of uploadedFiles) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      if (file.type === 'image/tiff') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const buffer = e.target.result;
          const tiff = await GeoTIFF.fromArrayBuffer(buffer);
          const image = await tiff.getImage();
          const width = image.getWidth();
          const height = image.getHeight();
          const rgba = await image.readRGBA();
          const img = new ImageData(rgba, width, height);
  
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.putImageData(img, 0, 0);
          canvas.toBlob((blob) => {
            const originalName = file.name;
            const newName = originalName.substr(0, originalName.lastIndexOf('.')) + '.' + outputFormat.split('/')[1];
            saveAs(blob, newName);
          }, outputFormat);
        };
        reader.readAsArrayBuffer(file);
      } else {
        const img = new Image();
        img.src = URL.createObjectURL(file);
  
        await new Promise((resolve) => {
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
              const originalName = file.name;
              const newName = originalName.substr(0, originalName.lastIndexOf('.')) + '.' + outputFormat.split('/')[1];
              saveAs(blob, newName);
              setProcessing(false);
              resolve();
            }, outputFormat);
          };
        });
      }
    }
  
    setConvertedFiles((prevFiles) => {
      const newFiles = [...prevFiles, ...uploadedFiles.map(file => `converted.${outputFormat.split('/')[1]}`)];
      return newFiles.slice(Math.max(newFiles.length - 10, 0));
    });
  };
  


  return (
    <div className={styles.converterContainer}>
      <h1>Image Format Converter</h1>
      <input
        type="file"
        accept={inputFormat}
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        id="file-input"
      />

      <div
        className={styles.uploadZone}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onClick={() => document.getElementById('file-input').click()}
      >
        Upload Image or Drag and Drop here

        <div className={styles.uploadedFiles}>
          <h5>Uploaded Files:</h5>
          <ul>
            {uploadedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      </div>

      {processing && <div className={styles.processingBar}>Processing...</div>}

      <Form className={styles.converterForm}>
        <Row>
          <Col>
            <Form.Label>Input Format</Form.Label>
            <Form.Control
              as="select"
              value={inputFormat}
              onChange={handleInputFormatChange}
              className={styles.converterSelect}
            >
              <option value="image/jpeg">JPEG</option>
              <option value="image/png">PNG</option>
              <option value="image/webp">WebP</option>
              <option value="image/tiff">TIFF</option>
              <option value="image/heic">HEIC</option>
              <option value="image/heif">HEIF</option>
              <option value="image/ico">ICO</option>
              <option value="image/jfif">JFIF</option>
            </Form.Control>
          </Col>
          <Col>
            <Form.Label>Output Format</Form.Label>
            <Form.Control
              as="select"
              value={outputFormat}
              onChange={handleOutputFormatChange}
              className={styles.converterSelect}
            >
              <option value="image/jpeg">JPEG</option>
              <option value="image/png">PNG</option>
              <option value="image/webp">WebP</option>
              <option value="image/tiff">TIFF</option>
              <option value="image/heic">HEIC</option>
              <option value="image/heif">HEIF</option>
              <option value="image/ico">ICO</option>
              <option value="image/jfif">JFIF</option>
            </Form.Control>
          </Col>
        </Row>
      </Form>

      <Button
        variant="dark"
        onClick={convertImage}
        className={`mt-3 ${styles.converterBtn}`}
        disabled={!selectedFile}
      >
        Convert Image
      </Button>
      <Button
        variant="dark"
        onClick={resetQueue}
        className={`mt-3 ${styles.converterBtn}`}
        disabled={uploadedFiles.length === 0}
      >
        Reset Queue
      </Button>
      {/* <div className={styles.convertedFiles}>
        <h5>Last 5 Converted Files:</h5>
        <ul>
          {convertedFiles.map((file, index) => (
            <li key={index}>{file}</li>
          ))}
        </ul>
      </div> */}
    </div>

  );
};


export default ImageFormatConverter;
