import React, { useState } from 'react';
import './css/ImageWatermark.css';
import { Helmet } from 'react-helmet';

import {
  Navbar,
  Nav,
  Form,
  Container,
  Row,
  Col,
  Card,
  Button,
  ProgressBar,
  Modal
} from 'react-bootstrap';

const ImageWatermarker = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [watermarkText, setWatermarkText] = useState('');
  const [watermarkType, setWatermarkType] = useState('single');
  const [watermarkSize, setWatermarkSize] = useState('medium');
  const [downloadUrl, setDownloadUrl] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreviewImage(null);
    }
  };

  const handleWatermarkTextChange = (e) => {
    setWatermarkText(e.target.value);
  };

  const handleWatermarkTypeChange = (e) => {
    setWatermarkType(e.target.value);
  };

  const handleWatermarkSizeChange = (e) => {
    setWatermarkSize(e.target.value);
  };


  const handleDownload = () => {
    if (downloadUrl) {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${selectedFile.name}_watermarked.jpg`;
      link.click();
    }
  };
  

  const watermarkImage = async () => {
    if (!selectedFile || !watermarkText) {
      alert('Please select an image and enter a watermark text.');
      return;
    }
  
    try {
      const image = await loadImage(selectedFile);
      const watermarkedImage = await createWatermarkedImage(image);
  
      setDownloadUrl(URL.createObjectURL(watermarkedImage));
    } catch (error) {
      console.error('Error watermarking image:', error);
    }
  };

  const loadImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.src = e.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const createWatermarkedImage = (image) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
  
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);
  
      const watermarkFontSize = getWatermarkFontSize();
      context.font = watermarkFontSize;
      context.fillStyle = 'rgba(255, 255, 255, 0.5)';
      context.textAlign = 'center';
  
      if (watermarkType === 'single') {
        const watermarkX = canvas.width / 2;
        const watermarkY = canvas.height / 2 + parseInt(watermarkFontSize) / 2;
        context.fillText(watermarkText, watermarkX, watermarkY);
      } else if (watermarkType === 'multiple') {
        const watermarkSize = getWatermarkSize();
        const xCount = Math.ceil(canvas.width / watermarkSize);
        const yCount = Math.ceil(canvas.height / watermarkSize);
  
        for (let x = 0; x < xCount; x++) {
          for (let y = 0; y < yCount; y++) {
            const watermarkX = x * watermarkSize + watermarkSize / 2;
            const watermarkY = y * watermarkSize + watermarkSize / 2 + parseInt(watermarkFontSize) / 2;
            context.fillText(watermarkText, watermarkX, watermarkY);
          }
        }
      }
  
      canvas.toBlob((blob) => resolve(blob), 'image/jpeg');
    });
  };

  

  const getWatermarkFontSize = () => {
    switch (watermarkSize) {
      case 'small':
        return '60px';
      case 'medium':
        return '80px';
      case 'large':
        return '120px';
      default:
        return '80px';
    }
  };

  const getWatermarkSize = () => {
    switch (watermarkSize) {
      case 'small':
        return 100;
      case 'medium':
        return 80;
      case 'large':
        return 50;
      default:
        return 90;
    }
  };

  const handleReset = () => {
    setSelectedFile(null);
    setPreviewImage(null);
    setWatermarkText('');
    setWatermarkType('single');
    setWatermarkSize('medium');
    setDownloadUrl('');
  };

  return (

    <div className="container-fluid">
      <Row>
        <Col md={8} lg={12}>
          <div
            className="border border-dark rounded p-3 my-5 d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: '60vh' }}>

            <h1 className="title">Image Watermarker</h1>
            <div className="input-container">
              <label className="label">Upload Image:</label>
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </div>
            {previewImage && (
              <div className="preview-container">
                <img src={previewImage} alt="Preview" className="preview-image" style={{ maxWidth: '100%', maxHeight: '300px',paddingBottom:'10px' }}/>
              </div>
            )}
            <div className="input-container">
              <label className="label">Watermark Text:</label>
              <input type="text" value={watermarkText} onChange={handleWatermarkTextChange} />
            </div>
            <div className="input-container">
              <label className="label">Watermark Type:</label>
              <select value={watermarkType} onChange={handleWatermarkTypeChange}>
                <option value="single">Single Watermark</option>
                <option value="multiple">Multiple Watermarks</option>
              </select>
            </div>
            <div className="input-container">
              <label className="label">Watermark Size:</label>
              <select value={watermarkSize} onChange={handleWatermarkSizeChange}>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </select>
            </div>
            <div className="button-container">
              <button className="watermark-button" onClick={watermarkImage}>
                Watermark Image
              </button>
            </div>
            {downloadUrl && (
              <div className="download-container">
                <button className="download-button" onClick={handleDownload}>
                  Download Watermarked Image
                </button>
              </div>
            )}
            {/* <div className="reset-container">
              <button className="reset-button" onClick={handleReset}>
                Reset Canvas
              </button>
            </div> */}
            {selectedFile && (
              <button className="btn btn-secondary mt-2" onClick={handleReset}>
                Reset
              </button>
            )}

          </div>


        </Col>

      </Row>


    </div>

  );
};

export default ImageWatermarker;
