import React, { useState } from 'react';

function ImageUpload({ onImageUpload }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    onImageUpload(event.target.files[0]);
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {selectedImage && <img src={selectedImage} alt="Selected" height="200" />}
    </div>
  );
}

export default ImageUpload;
