import React, { useState } from 'react';
import { extractColors } from 'extract-colors';
import { Button, Container, ProgressBar, Row, Col, Card } from 'react-bootstrap';
import './css/colorful.css'
import jsPDF from "jspdf";


function App() {
  const [colors, setColors] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [progress, setProgress] = useState(0);

  const onFileChange = (event) => {
    const file = event.target.files[0];

    // Image format validation
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (validImageTypes.includes(file.type)) {
      setSelectedFile(URL.createObjectURL(file));
    } else {
      alert('Please upload an image file (gif, jpeg, or png).');
    }
  };

  const extractImageColors = async () => {
    if (selectedFile) {
      try {
        setProgress(50);
        const colors = await extractColors(selectedFile);
        setColors(colors);
        setProgress(0);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const downloadPalette = () => {
    const palette = colors.map((color) => color.hex).join(', ');
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(palette)
    );
    element.setAttribute('download', 'palette.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  const downloadPaletteAsPDF = () => {
    const doc = new jsPDF();
    
    colors.forEach((color, index) => {
      doc.setFillColor(color.hex);
      doc.rect(10, 10 + index*20, 10, 10, "F");
      doc.text(`Color: ${color.hex}`, 25, 15 + index*20);
    });
  
    doc.save('color_palette.pdf');
  };
  

  const copyHexCode = (hexCode) => {
    navigator.clipboard.writeText(hexCode);
  };

  return (
    <Container className="text-center">
      <h1 className="my-4">Color Palette Extractor</h1>
      <div className="mb-3" style={{ border: '1px solid black', padding: '10px' }}>
        <input type="file" onChange={onFileChange} />
        <Button onClick={extractImageColors} className="ml-2">Extract Colors</Button>
        {progress > 0 && <ProgressBar now={progress} className="mt-2" />}
      </div>
      {colors.length > 0 && (
        <button className="btn btn-primary" style={{ marginBottom: '20px', paddingLeft: '10px' }} onClick={downloadPaletteAsPDF}>Download Palette as PDF</button>
       
      )}
      <Row>
        {colors.map((color, index) => (
          <Col key={index} md={4} className="mb-4">
            <Card style={{ backgroundColor: color.hex }}>
              <Card.Body>
                <Card.Title>{color.hex}</Card.Title>
                <Button variant="light" onClick={() => copyHexCode(color.hex)}>Copy Hex Code</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default App;
