import React, { useState } from 'react';
import EXIF from 'exif-js';
import styles from './css/ImageMetadataViewer.module.css';
import {
    Navbar,
    Nav,
    Form,
    Container,
    Row,
    Col,
    Card,
    Button,
    ProgressBar,
    Modal
} from 'react-bootstrap';

function ImageMetadataViewer() {
    const [imageData, setImageData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [previewImage, setPreviewImage] = useState(null);


    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/tiff'];

        if (validImageTypes.includes(file.type)) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setPreviewImage(event.target.result);
            };
            reader.readAsDataURL(file);
            EXIF.getData(file, function () {
                const allMetaData = EXIF.getAllTags(this);
                setImageData(allMetaData);
            });

        } else {
            alert('Invalid file type. Please upload a JPEG, PNG or TIFF file.');
            setImageData(null);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const getFilteredData = () => {
        const dataToDisplay = { ...imageData };

        // Remove MakerNote field
        delete dataToDisplay['MakerNote'];
        delete dataToDisplay['UserComment'];


        if (!searchTerm) {
            return dataToDisplay;
        }

        return Object.keys(dataToDisplay)
            .filter((key) => key.toLowerCase().includes(searchTerm.toLowerCase()))
            .reduce((obj, key) => {
                obj[key] = dataToDisplay[key];
                return obj;
            }, {});
    };

    


    return (


        <div className="container-fluid">
            <Row>
                <Col md={8} lg={12}>
                    <div
                        className="border border-dark rounded p-3 my-5 d-flex flex-column justify-content-center align-items-center"
                        style={{ minHeight: '60vh' }}>

                        <h1>Image Metadata Viewer</h1>
                        
                        {previewImage && (
                                <img src={previewImage} alt="Preview" style={{ maxWidth: '50%', maxHeight: '200px', marginBottom: '10px' }} />
                            )}
                        <div className={styles.inputGroup}>
                            
                            <input type="file" onChange={handleImageUpload} />
                            <input type="text" placeholder="Search metadata..." onChange={handleSearchChange} />
                        </div>
                        {imageData && (
                            <div className={styles.metadataBox}>
                                <h2>Image Metadata:</h2>
                                <pre>{JSON.stringify(getFilteredData(), null, 2)}</pre>
                            </div>
                        )}

                    </div>


                </Col>

            </Row>


        </div>
    );
}

export default ImageMetadataViewer;
