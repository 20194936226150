import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <div className="text-center border p-4">
                <h1>Privacy Policy</h1>
                <p>
                    This Privacy Policy governs the manner in which we handle user data for the web app 'imagenize-imagetools-1' in compliance with GDPR and CPRA.
                </p>
                <h2>1. Collection and Use of Information</h2>
                <p>
                    We do not collect any personal data or personally identifiable information from users, ensuring compliance with GDPR and CPRA. All operations are performed client-side, and no data is stored or transmitted to any server. Any data processed by the web app remains within the user's browser and is not accessed or shared by us.
                </p>
                <h2>2. Cookies and Tracking</h2>
                <p>
                    We may use cookies or similar technologies for analytical purposes, strictly adhering to GDPR and CPRA guidelines. These cookies help us analyze user behavior and improve the performance and functionality of our web app. The cookies used do not collect any personally identifiable information and are not used for targeted advertising or profiling purposes.
                </p>
                <h2>3. Data Security</h2>
                <p>
                    We prioritize the security of your data, as required by GDPR and CPRA. We implement appropriate technical and organizational measures to protect against unauthorized access, loss, or alteration of data. Despite these measures, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security of your information.
                </p>
                <h2>4. Third-Party Services</h2>
                <p>
                    Our web app may use third-party services, such as Google Analytics, for analytical purposes. These services may use cookies or similar technologies to collect non-personally identifiable information about your usage of the web app, in accordance with GDPR and CPRA regulations. Please review the privacy policies of these third-party services for more information on their data processing practices.
                </p>
                <h2>5. Children's Privacy</h2>
                <p>
                    Our web app is not intended for use by children under the age of 13, as required by GDPR and CPRA. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us immediately, and we will take steps to remove such information from our systems.
                </p>
                <h2>6. Changes to the Privacy Policy</h2>
                <p>
                    We reserve the right to update or modify this Privacy Policy at any time, ensuring compliance with GDPR and CPRA regulations. Any changes will be effective immediately upon posting the revised Privacy Policy on this page. We encourage you to periodically review this Privacy Policy for any updates or changes.
                </p>
                <p>
                    This Privacy Policy applies solely to our web app 'imagenize-imagetools-1' and does not apply to any other websites or services that may be linked from our web app.
                </p>

            </div>



        </div>);
};

export default PrivacyPolicy;
