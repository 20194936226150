import React from 'react';
import './css/ContactForm.css';

function ContactForm() {
  return (
    
    <div className="contact-form">
      <form>
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="Email" />
        <textarea placeholder="Message"></textarea>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactForm;
