import React, { useState } from 'react';
import Tesseract from 'tesseract.js';
import { saveAs } from 'file-saver';
import styles from './css/TextExtractor.module.css';

import {
  Navbar,
  Nav,
  Form,
  Container,
  Row,
  Col,
  Card,
  Button,
  ProgressBar,
  Modal
} from 'react-bootstrap';

const TextExtractor = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  
  const [processing, setProcessing] = useState(false);

  // const handleFileInputChange = (event) => {
  //   setSelectedFiles(event.target.files);
  // };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);

    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const resetForm = () => {
    setSelectedFiles([]);
    setPreviewImage(null);
  };


  const extractText = async () => {
    setProcessing(true);

    for (const file of selectedFiles) {
      try {
        const { data: { text } } = await Tesseract.recognize(
          file,
          'eng',
          { logger: (m) => console.log(m) }
        );

        const textBlob = new Blob([text], { type: 'text/plain' });
        saveAs(textBlob, `${file.name}.txt`);
      } catch (error) {
        console.error('Error processing image:', error);
      }
    }

    setProcessing(false);
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col md={8} lg={12}>
          <div
            className="border border-dark rounded p-3 my-5 d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: '60vh' }}>

            <h1>Image Text Extractor</h1>
            <input
              type="file"
              accept="image/jpeg, image/png, image/webp, image/tiff, image/bmp"
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              id="file-input"
              multiple
            />
            <label htmlFor="file-input" className="btn btn-dark mb-3">
              Upload Image
            </label>
            {previewImage && (
              <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px',paddingBottom:'10px' }} />
            )}
            {processing && <div className={styles.processingBar}>Processing...</div>}
            
            <Form>
              <Button
                variant="dark"
                onClick={extractText}
                className={`mt-3 ${styles.extractorBtn}`}
                disabled={selectedFiles.length === 0}
              >
                Extract Text
              </Button>
              
            </Form>
            {selectedFiles.length > 0 && (
              <button className="btn btn-secondary mt-2" onClick={resetForm}>
                Reset
              </button>
            )}

          </div>


        </Col>

      </Row>


    </div>
  );
};

export default TextExtractor;
